import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SelectItem } from 'primeng/api';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { ICustomer, CustomerDto } from '../../domain/customer';
import { IListEnvelope } from '../../domain/list.interface';


@Injectable({
    providedIn: 'root'
})
export class CustomerService {
 
    constructor(private http: HttpClient) {}

    
    async getCustomerItems(): Promise<SelectItem[]> {
        return new Promise((resolve, reject) => {
            this.http.get<IListEnvelope<ICustomer>>(`${environment.url}customers?all=true`)
                    .subscribe((response) => {
                        const customers = response?.rows?.map<SelectItem>(m => ({ value: m._id, label: m.name }));
                        resolve(customers);
                    },
                    (error) => reject(error)
                    );
        });
    }

    getAllCustomers(): Observable<IListEnvelope<ICustomer>> {
        return this.http.get<IListEnvelope<ICustomer>>(`${environment.url}customers?all=true`);                 
    }

    getCustomers(page?: number, limit?: number): Observable<IListEnvelope<ICustomer>> {
        return this.http.get<IListEnvelope<ICustomer>>(`${environment.url}customers?limit=${limit ?? ''}&page=${page ?? ''}`);       
    }

    addCustomer(customer: CustomerDto): Observable<ICustomer> {
        return this.http.post<ICustomer>(`${environment.url}customers/create`, customer);
    }

    editCustomer(id: string, customer: CustomerDto): Observable<ICustomer> {
        return this.http.put<ICustomer>(`${environment.url}customers/${id}`, customer);
    }

    detailCustomer(id: string): Observable<any> {
        return this.http.get<ICustomer>(`${environment.url}customers/${id}`);
    }

    deleteCustomer(id: string): Observable<any> {
        return this.http.delete(`${environment.url}customers/${id}`);
    }
}
