<div class="card" *ngIf="dashboardData && dashboardData.length > 0">
    <div class="header">
        <div class="title">
            HİZMET
        </div>
    </div>
    <div class="content">
        <div *ngFor="let item of dashboardData; trackBy: trackByFn">
        <div *ngIf="checkServiceType(item.service)"
            class="sub-container indigo"
            [class.green]="item.selected"
            [class.selected]="item.selected"
            [ngStyle]="{ 'cursor': roleUser === 'customer' ? 'default' : 'pointer' }" (click)="onClick(item)" >
            <button *ngIf="roleUser !=='customer'" pButton pRipple type="button" icon="pi pi-external-link"
                    class="btn p-button-rounded p-button-text p-button-sm"
                    (click)="goToList(item)"></button>
            <div class="number">{{ item.count }}</div>
            <div class="text">{{ item.name }}</div>
        </div>
    </div>
    </div>
</div>