import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { CaseInsensitiveMatcher } from './services/common/caseinsensitiveurlmatcher';
import { DefaultLayoutComponent } from './layout/default-layout/default-layout.component';
import { LoginLayoutComponent } from './layout/login-layout/login-layout.component';
import { ErrorLayoutComponent } from './layout/error-layout/error-layout.component';
import { ResourceNotFoundComponent } from './layout/resource-not-found/resource-not-found.component';
import { AccessDeniedComponent } from './layout/access-denied/access-denied.component';
import { HomeComponent } from './pages/home/home.component';
import { AuthGuard } from './services/auth/auth.guard';
import { ResetPasswordComponent } from './pages/login/reset-password/reset-password.component';
import { DownloadReportComponent } from './pages/download-report/download-report.component';
import { LoginComponent } from './pages/login/login/login.component';
import { ForgotPasswordComponent } from './pages/login/forgot-password/forgot-password.component';
import { HomeGuard } from './services/auth/home.guard';
import { OccurrenceParameterComponent } from './pages/occurrence-parameter/occurrence-parameter.component';
import { LogListComponent } from './pages/log-list/log-list.component';
import { SupplierStaffListComponent } from './components/supplier-staff-list/supplier-staff-list.component';
import { SupplierCarListComponent } from './components/supplier-car-list/supplier-car-list.component';
import { AboutComponent } from './components/about/about.component';
import { AnnouncementAssignedListComponent } from './pages/operator/announcement/components/announcement-assigned-list/announcement-assigned-list.component';
import { CampaignsComponent } from './pages/campaigns/campaigns.component';
import { CopyStatusesForCustomersComponent } from './pages/copy-statuses-for-customers/copy-statuses-for-customers.component';

const appRoutes: Routes = [
    {
        path: '',
        component: DefaultLayoutComponent,
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard, HomeGuard],
        children: [
            { path: '', component: HomeComponent },
            { path: 'home', component: HomeComponent },
        ],
        pathMatch: 'full'
    },
    {
        matcher: CaseInsensitiveMatcher('login'),
        component: LoginLayoutComponent,
        children: [
            { path: '', component: LoginComponent },
        ],
        pathMatch: 'full'
    },
    {
        matcher: CaseInsensitiveMatcher('forgot-password'),
        component: LoginLayoutComponent,
        children: [
            { path: '', component: ForgotPasswordComponent },
        ],
        pathMatch: 'full'
    },
    {
        matcher: CaseInsensitiveMatcher('reset-password'),
        component: DefaultLayoutComponent,
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        children: [
            { path: '', component: ResetPasswordComponent },
        ],
        pathMatch: 'full'
    },
    {
        matcher: CaseInsensitiveMatcher('download-report/:id'),
        component: DefaultLayoutComponent,
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        children: [
            { path: '', component: DownloadReportComponent },
        ],
        pathMatch: 'full'
    },
    {
        matcher: CaseInsensitiveMatcher('log-list'),
        component: DefaultLayoutComponent,
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        children: [
            { path: '', component: LogListComponent },
        ],
        pathMatch: 'full'
    },
    {
        matcher: CaseInsensitiveMatcher('supplier-staff'),
        component: DefaultLayoutComponent,
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        children: [
            { path: '', component: SupplierStaffListComponent },
        ],
        pathMatch: 'full'
    },
    {
        matcher: CaseInsensitiveMatcher('supplier-car'),
        component: DefaultLayoutComponent,
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        children: [
            { path: '', component: SupplierCarListComponent },
        ],
        pathMatch: 'full'
    },
    {
        matcher: CaseInsensitiveMatcher('campaigns/campaign-creat'),
        component: DefaultLayoutComponent,
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        children: [
            { path: '', component: CampaignsComponent },
        ],
        pathMatch: 'full'
    },
    {
        path: 'operator',
        component: DefaultLayoutComponent,
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        loadChildren: () => import('./pages/operator/operator.module').then(mod => mod.OperatorModule),
    },
    {
        path: 'octopus',
        component: DefaultLayoutComponent,
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        loadChildren: () => import('./octopus-manager/octopus-manager.module').then(mod => mod.OctopusManagerModule),
    },
    {
        path: 'settings',
        component: DefaultLayoutComponent,
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        loadChildren: () => import('./pages/settings/settings.module').then(mod => mod.SettingsModule),
    },
    {
        path: 'assignedAnnouncements',
        component: DefaultLayoutComponent,
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        loadChildren: () => import('./pages/operator/announcement/announcement.module').then(mod => mod.AnnouncementModule),
    },
    {
        path: 'about',
        component: DefaultLayoutComponent,
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        children: [
            { path: '', component: AboutComponent },
        ],
    },
    {
        path: 'supplier-operations',
        component: DefaultLayoutComponent,
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        loadChildren: () => import('./pages/supplier/supplier-operations.module').then(mod => mod.SupplierOperationsModule),
    },
    {
        path: 'parameters',
        component: DefaultLayoutComponent,
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        children: [{ path: '', component: OccurrenceParameterComponent }]
    },
    {
        path: 'coy-statuses',
        component: DefaultLayoutComponent,
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        data:{expectedPermissions:["CopyStatusForCustomers"]} ,
        children: [{ path: '', component: CopyStatusesForCustomersComponent }]
    },
    {
        path: 'notfound',
        component: ErrorLayoutComponent,
        children: [{ path: '', component: ResourceNotFoundComponent }]
    },
    {
        path: 'accessdenied',
        component: ErrorLayoutComponent,
        children: [{ path: '', component: AccessDeniedComponent }]
    },
    { path: '**', redirectTo: 'notfound' }
];

@NgModule({
    imports: [
        RouterModule.forRoot(appRoutes,
            {
                preloadingStrategy: PreloadAllModules,
                onSameUrlNavigation: 'reload',
                scrollPositionRestoration: 'enabled'
            })
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
