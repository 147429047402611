<p-card>
    <h1>
        Müşteri Durumları Kopyalama Ekranı
    </h1>
  
    <div class="p-fluid p-formgrid p-grid">
        <div class="p-field p-col-12 p-md-6">
            <label for="customerId">Kaynak Müşteri</label>
            <p-dropdown 
                id="customerId" 
                placeholder="[Durumları İletecek Müşteriyi Seçiniz]" 
                [options]="customerList" 
                [(ngModel)]="transmitterCustomer" 
                appFilterDropdown
            ></p-dropdown>
        </div>
        <div class="p-field p-col-12 p-md-6">
            <label for="serviceType">Hizmet Tipleri</label>
            <p-multiSelect 
                [options]="serviceTypeList" 
                [(ngModel)]="givingSituations" 
                defaultLabel="[Durumları İletecek Müşterinin Hizmet Tiplerini Seçiniz]" 
                selectedItemsLabel="{0} Servis Tipi Seçildi" 
                optionValue="value" 
                optionLabel="label" 
                appFilterDropdown
            ></p-multiSelect>
        </div>
    </div>
    
    <hr>

    <div class="p-fluid p-formgrid p-grid">
        <div class="p-field p-col-12 p-md-6">
            <label for="buyerCustomer">Aktarılacak Müşteri(ler)</label>
            <!-- <p-dropdown 
                id="buyerCustomer" 
                placeholder="[Durumları Alacak Müşteriyi Seçiniz]" 
                [options]="customerList" 
                [(ngModel)]="buyerCustomer" 
                appFilterDropdown
            ></p-dropdown> -->
            <p-multiSelect 
            [options]="customerList" 
            [(ngModel)]="buyerCustomer" 
            defaultLabel="[Durumları Alacak Müşteriyi Seçiniz]" 
            selectedItemsLabel="{0} Müşteri Seçildi" 
            optionValue="value" 
            optionLabel="label" 
            appFilterDropdown
        ></p-multiSelect>
        </div>
    </div>
    
    <ng-template pTemplate="footer">
        <div class="p-grid" style="justify-content: flex-end;">
            <div class="p-col">
                <p-button 
                    style="margin-left: 90%;"
                    label="Durumları Kopyala"
                    (click)="transaction()"
                ></p-button>
            </div>
        </div>
    </ng-template>
</p-card>
