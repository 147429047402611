export enum RolesEnum {
  superAdmin = 'superAdmin',
  admin = 'admin',
  moderator = 'moderator',
  user = 'user',
  supplier = 'supplier',
  supplierStaff = 'supplierStaff',
  callCenter = 'callCenter',
  customer = 'customer'

}
