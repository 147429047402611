import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CopyStatusesService {

  constructor(private http: HttpClient) { }


  updateStatuses(firstCustomer: string, firstCustomerStatuses:any[], secondCustomer:any[]){

    const body = {
      firstCustomer,
      firstCustomerStatuses,
      secondCustomer,
    };
    return this.http.put<any>(`${environment.url}copy-status-for-customers/update`, body);

  }
}
