import { Component } from '@angular/core';
import { SelectItem } from 'primeng/api';
import { CustomerService } from '../../services/settings/customer.service';
import { ServiceService } from '../../services/settings/service.service';
import { CopyStatusesService } from '../../services/copy-statuses/copy-statuses.service';
import { AppMessageService } from '../../services/common/app-message.service';

@Component({
  selector: 'app-copy-statuses-for-customers',
  templateUrl: './copy-statuses-for-customers.component.html',
  styleUrls: ['./copy-statuses-for-customers.component.scss']
})
export class CopyStatusesForCustomersComponent {

  customerList: SelectItem[];
  transmitterCustomer = ''
  buyerCustomer : SelectItem[]=[]



  serviceTypeList: SelectItem[];
  givingSituations : SelectItem[]=[]


  constructor(
      private customerService: CustomerService,
      private serviceService: ServiceService,
      private copyStatusService: CopyStatusesService,
      private appMessageService: AppMessageService
  ){}

  async ngOnInit(){
    const item: SelectItem[] = [{ value: "[HEPSİ]", label: "[HEPSİ]" }];

    this.serviceTypeList = await this.serviceService.getServiceItems();
    this.customerList = (await this.customerService.getCustomerItems());
  }
  transaction(){
    this.copyStatusService.updateStatuses(this.transmitterCustomer, this.givingSituations, this.buyerCustomer).subscribe(
      res=> {
        console.log(res)
        if(res && res.success){
          this.appMessageService.successToast('İşlem Başarılı', res.message)
        }
        if(res && !res.success){
          this.appMessageService.errorToast('HATA', res.message)
        }
      }
    )
  }
}
